<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <v-card class="card">
      <v-card-title class="heading justify-center">
        <span>Book Donation</span>
      </v-card-title>
      <v-card-text>
        <div>
          <div>
            <v-form ref="form">
              <v-row class="justify-center">
                <v-col xl="4" md="4" sm="4">
                  <label class="add-text">Book Condition :</label>
                  <v-select v-model="bc_id" :items="allbc" item-text="name" item-value="bc_Id" outlined dense
                    placeholder="Select Book Condition" :rules="[rules.required]" clearable required></v-select>
                </v-col>
                <v-col xl="4" md="4" sm="4">
                  <label class="add-text">Probable Delivery Date :</label><br>
                  <input type="date" placeholder="Select Date" v-model="delv_date" class="form-control">
                </v-col>
                <v-col xl="4" md="4" sm="4">
                  <label class="add-text">Number of Copy</label>
                  <v-text-field v-model="noofcopy" dense type="number" outlined placeholder="Enter Number of Copy"
                    :rules="[rules.required]" clearable required></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col class="text-center">
                  <v-btn depressed color="success" @click="savedata()">
                    <v-icon>mdi-plus</v-icon>
                    ADD
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </div>
        <v-simple-table fixed-headerheight="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Sr. No</th>
                <th class="text-left">Book Condition</th>
                <th class="text-left">Reqest Date</th>
                <th class="text-left">Probable Delivery Date</th>
                <th class="text-left">Number of Copy</th>
                <th class="text-left">Status</th>
                <th class="text-left">Edit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in userlist" :key="item">
                <td>{{ i + 1 }}</td>
                <td>{{ item.bookcondition }}</td>
                <td>{{ item.request_date_date }}</td>
                <td>{{ item.displey_expected_delivery_date }}</td>
                <td>{{ item.number_of_copies }}</td>
                <td><v-switch v-model="item.isactive" @change="activeOn(item)"></v-switch></td>
                <td><v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <!-- edit -->
    <v-dialog v-model="dialog_edit" persistent max-width="600px">
      <v-card>
        <v-card-title class="heading justify-center">
          <span class="headline">Edit</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete v-model="editedItem.bc_id" :items="allbc" item-text="name" item-value="bc_Id" outlined
                  dense placeholder="Select Book Condition" :rules="[rules.required]" clearable required></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <input type="date" placeholder="Select Date" v-model="editedItem.delv_date" class="form-control">
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.noofcopy" dense type="number" outlined
                  placeholder="Enter Number of Copy" :rules="[rules.required]" clearable required></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_edit = false"> Close</v-btn>
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    snackbar_msg: "",
    snackbar: false,
    color: "",
    allbc: [],
    rules: { required: value => !!value || "Required." },
    editedItem: {
      bdid: null,
      bc_id: null,
      bc: null,
      delv_date: null,
      noofcopy: null,
    },
    bc_id: null,
    delv_date: null,
    noofcopy: null,
    userlist: null,
    dialog_edit: false,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Book Donation',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
  }),
  computed: {
    formTitle() {
      return 'Edit Item'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    editItem(item) {
      this.editedItem.bdid = item.bdr_Id
      this.editedItem.bc_id = item.bookcondition_id
      this.editedItem.bc = item.bookcondition_id
      this.editedItem.noofcopy = item.number_of_copies
      this.editedItem.delv_date = item.expected_delivery_date
      this.dialog_edit = true
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    savedata() {
      const data = {
        bc_id: this.bc_id,
        delv_date: this.delv_date,
        noofcopy: this.noofcopy,
      };
      axios.post("MemberProcess/savebookdata", data)
        .then(res => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", 'Successfully Save!!');
            this.onLoad();
            this.clear()
          }
        })
        .catch(error => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          window.console.log(error)
        })

    },
    onLoad: function () {
      axios.get("MemberProcess/bookDonation")
        .then(res => {
          //console.log("res------->"+res.data.userType)
          if (res.data.msg == "200") {
            this.allbc = res.data.allbc,
              this.userlist = res.data.booksList
          }
        })
        .catch(error => {
          window.console.log(error)
        })
    },
    activeOn(item) {
      console.log(item);
      axios
        .post("/MemberProcess/donatebookIsactive", item)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "Updated successfully...");
            this.onLoad()
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },
    save() {
      axios.post("/MemberProcess/updatedata", this.editedItem)
        .then(res => {
          //console.log("res------->"+res.data.userType)
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "Updated successfully...");
            this.onLoad();
            this.dialog_edit = false
          }
        })
        .catch(error => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          window.console.log(error)
        })
      this.close()
    },
    clear() {
      this.delv_date = null
      this.bc_id = null
      this.noofcopy = null
    }
  }
}
</script>
<style scoped>
.heading {
  background-color: #3f51b5;
  padding: 0.3rem;
  color: white;
}

.v-icon {
  cursor: pointer;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.p-btn {
  padding: 0px 12px 12px 12px;
}
</style>




